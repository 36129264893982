import { gql } from '@apollo/client';

export const tariffFragment = gql`
  fragment TariffFields on TariffType {
    createdAt
    updatedAt
    uuid
    name
    description
    startedDate
    endDate
    availableOrdersCount
    ordersCount
    paidOrdersCount
    type
    price
    status
    visible
    featureFlags {
      refundOrder
      transferToUserOrder
      transferToProductOrder
      repeatBuyOrder
    }
  }
`;

export const tariffsList = gql`
  query tariffsList($productUuid: UUID!, $status: [StatusEnum!]!) {
    tariffsList(
      params: { productUuid: $productUuid }
      status: $status
      filters: { order: { sort: Desc, sortBy: createdAt } }
    ) {
      rows {
        uuid
        name
        startedDate
        endDate
        status
        type
        ordersCount
        availableOrdersCount
        paidOrdersCount
      }
    }
  }
`;
export const tariff = gql`
  ${tariffFragment}
  query tariff($uuid: UUID!) {
    tariff(uuid: $uuid) {
      ...TariffFields
    }
  }
`;

export const updateTariff = gql`
  ${tariffFragment}
  mutation updateTariff($uuid: UUID!, $input: TariffInput!) {
    updateTariff(input: $input, uuid: $uuid) {
      ...TariffFields
    }
  }
`;

export const createTariff = gql`
  ${tariffFragment}
  mutation createTariff($input: TariffInput!) {
    createTariff(input: $input) {
      ...TariffFields
    }
  }
`;
