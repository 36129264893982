import React, { FC, MouseEvent, useState } from 'react';

import { createTgLink } from '@/shared';
import { router, RouterName } from '@app/router';
import { useSpecUserAction } from '@entities/Contact';
import {
  Button,
  Dropdown,
  DropdownItem,
  Maybe,
  MoreIcon,
  Paper,
  stopEvent,
  useCopyToClipboard,
} from '@letsdance/ui-kit';

interface ContactControlProps {
  uuid: string;
  name: string;
  username?: Maybe<string>;
  isSpecial: boolean;
}

export const ContactControl: FC<ContactControlProps> = ({
  isSpecial,
  username,
  uuid,
}) => {
  const { onAddUser, onRemoveUser } = useSpecUserAction();
  const [, copyToClipboard] = useCopyToClipboard();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleChangeDropdown = (val: boolean) => {
    setIsOpen(val);
  };

  const eventCallback = (callback: () => void) => (e: MouseEvent) => {
    setIsOpen(false);
    stopEvent(e);
    callback();
  };
  const onEdit = () => {
    window.open(router.urlFor(RouterName.AdminContactEdit, { uuid }), '_blank');
  };
  const onCopy = () => {
    if (username) {
      copyToClipboard(createTgLink(username));
    }
  };

  return (
    <Dropdown
      value={isOpen}
      targetSlot={
        <Button
          icon
          prependIcon={MoreIcon}
          variant="text"
          size="large"
          onClick={stopEvent}
        />
      }
      placement="bottom-end"
      width={260}
      zIndex={1000}
      onChange={handleChangeDropdown}>
      <Paper px={4} py={4} radius={6} className="paper-list">
        <DropdownItem
          fullWidth
          onClick={eventCallback(onEdit)}
          label="Редактировать"
        />
        {username && (
          <DropdownItem
            fullWidth
            onClick={eventCallback(onCopy)}
            label="Копировать ссылку"
          />
        )}
        {isSpecial ? (
          <DropdownItem
            fullWidth
            onClick={eventCallback(() => onRemoveUser(uuid))}
            label="Удалить из спецгостей"
          />
        ) : (
          <DropdownItem
            fullWidth
            onClick={eventCallback(() => onAddUser(uuid))}
            label="Перенести в спецгости"
          />
        )}
      </Paper>
    </Dropdown>
  );
};
