import React, { FC, useState } from 'react';

import {
  useUsersSpecialCountQuery,
  useUsersStatsQuery,
} from '@/apolloGenerated';
import { Container, useOrganizerId } from '@/shared';
import {
  ContactListFilter,
  ContactListFilterData,
  ContactTable,
} from '@features/Contact';
import { Divider, Spacer, StarIcon, TabItem, Tabs } from '@letsdance/ui-kit';

export enum ContactTabs {
  All = 'All',
  Active = 'Active',
  Special = 'Special',
}
export const ContactLists: FC = () => {
  const organizerId = useOrganizerId()!;
  const { data: statsData } = useUsersStatsQuery({
    skip: !organizerId,
    variables: { organizerId },
  });
  const { data: activeStatsData } = useUsersStatsQuery({
    skip: !organizerId,
    variables: { isActive: true, organizerId },
  });
  const { data: specialCountData } = useUsersSpecialCountQuery({
    skip: !organizerId,
    variables: { organizerId },
  });
  const allCount = statsData?.usersStats.count || 0;
  const activeCount = activeStatsData?.usersStats.count || 0;
  const specialCount = specialCountData?.specialUsers.count || 0;
  const [tab, setTab] = useState<ContactTabs>(ContactTabs.All);
  const [filter, setFilter] = useState<ContactListFilterData>({ search: '' });

  return (
    <>
      <Container>
        <Tabs initValue={tab} onChange={setTab}>
          <TabItem value={ContactTabs.All} label={`Все (${allCount})`} />
          <TabItem
            value={ContactTabs.Active}
            label={`Активные пользователи (${activeCount})`}
          />
          <TabItem
            style={{ alignItems: 'center', display: 'flex', gap: 4 }}
            value={ContactTabs.Special}>
            <StarIcon width={16} height={16} />
            Спецгости ({specialCount})
          </TabItem>
        </Tabs>
      </Container>
      <Divider />
      <Spacer size={12} />
      <Container>
        <ContactListFilter initValue={filter} onChange={setFilter} />
        <Spacer size={4} />
        <ContactTable type={tab} searchValue={filter.search} />
      </Container>
    </>
  );
};
