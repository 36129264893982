import { FC, useEffect, useMemo, useState } from 'react';

import {
  Maybe,
  TariffTypeEnum,
  UserSortKeys,
  UsersSpecialProductListQuery,
  UserType,
  useUsersSpecialProductListQuery,
} from '@/apolloGenerated';
import {
  formatSort,
  renderCellCheckbox,
  renderCellItemValue,
  useOrganizerId,
} from '@/shared';
import { validateTariff } from '@entities/Tariff';
import {
  Checkbox,
  LoaderOverlay,
  SortType,
  StarIcon,
  TableController,
  Typography,
} from '@letsdance/ui-kit';

const pageSize = 10;
const DEFAULT_PAGE = 1;

export interface SpecialContactSelectTableProps {
  searchValue: string;
  selected: UserType[];
  type?: TariffTypeEnum;
  productUuid?: Maybe<string>;
  onChange(uuids: UserType[]): void;
  requiredRegistration?: boolean;
  checkHasInvite?: boolean;
}

export const SpecialContactSelectTable: FC<SpecialContactSelectTableProps> = ({
  checkHasInvite,
  onChange,
  productUuid,
  requiredRegistration,
  searchValue,
  selected,
  type,
}) => {
  const organizerId = useOrganizerId()!;
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<UserSortKeys>(UserSortKeys.CreatedAt);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const { data: dataAll } = useUsersSpecialProductListQuery({
    skip: !organizerId || !productUuid,
    variables: { organizerId, productUuid: productUuid! },
  });
  const validationParams = {
    skipCheckInvite: !checkHasInvite,
    skipRegistered: !requiredRegistration,
  };
  const filterUsers = (
    users: UsersSpecialProductListQuery['specialUsers']['rows'],
  ): UserType[] =>
    users.filter(
      (el) =>
        validateTariff(
          {
            hasInviteByProduct: el.hasInviteByProduct,
            hasOrderByProduct: el.hasOrderByProduct,
            tariffType: type,
            userInfo: el,
          },
          validationParams,
        ).status,
    ) as UserType[];

  useEffect(() => {
    onChange(filterUsers(selected));
  }, [productUuid, type]);

  useEffect(() => {
    if (dataAll?.specialUsers.count !== selected.length && allSelected) {
      setAllSelected(false);
    }
  }, [productUuid, type]);

  const handleSetAllSelected = async (val: boolean) => {
    setAllSelected(val);
    if (val) {
      onChange(filterUsers(dataAll?.specialUsers.rows || []));
    } else {
      onChange([]);
    }
  };
  const headers = [
    { hide: true, key: true, value: 'uuid' },
    {
      title: (
        <Checkbox
          checked={allSelected}
          onChange={(e) => handleSetAllSelected(e.target.checked)}
        />
      ),
      value: 'check',
      width: 48,
    },
    { value: 'special', width: 48 },
    { title: 'ФИО', value: 'name' },
    { title: 'Данные', value: 'data' },
  ];

  const { data, loading, previousData } = useUsersSpecialProductListQuery({
    skip: !organizerId || !productUuid,
    variables: {
      filters: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
        search: searchValue,
      },
      organizerId,
      productUuid: productUuid!,
    },
  });
  const curData = data || previousData;

  const handleChange = (checked: boolean, user: UserType) => {
    if (checked) {
      onChange([...selected, user]);
    } else {
      onChange(selected.filter((el) => el.uuid !== user.uuid));
    }
  };

  const rowTemplate = (
    user: UsersSpecialProductListQuery['specialUsers']['rows'][0],
  ) => {
    const validation = validateTariff(
      {
        hasInviteByProduct: user.hasInviteByProduct,
        hasOrderByProduct: user.hasOrderByProduct,
        tariffType: type,
        userInfo: user,
      },
      validationParams,
    );
    const checked = selected.some((el) => user.uuid === el.uuid);

    return {
      check: renderCellCheckbox({
        disabled: !validation.status,
        onChange(val: boolean) {
          handleChange(val, user as UserType);
        },
        tooltipText: validation.message,
        value: checked,
      }),
      data: renderCellItemValue({
        label: (
          <div className="flex">
            {user.username ? (
              <Typography
                variant="body-14"
                color="on-surface-primary-1"
                rel="noreferrer">
                @{user.username}
              </Typography>
            ) : (
              '-'
            )}{' '}
            {user.phone && ' / ' + user.phone}
          </div>
        ),
        value: user.email,
      }),
      name: renderCellItemValue({
        label: `${user.last_name || ''} ${user.first_name}`,
        value: user.uuid,
      }),
      special: <StarIcon color="var(--on-surface-secondary-1)" />,
      uuid: user.uuid,
    };
  };
  const items = useMemo(
    () => (curData?.specialUsers.rows || []).map((item) => rowTemplate(item)),
    [curData, selected],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={curData?.specialUsers.count}
        pageSize={pageSize}
        onChangePage={setPage}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as UserSortKeys);
        }}
        notResetPage
      />
    </div>
  );
};
