import { FC, useEffect, useMemo, useState } from 'react';

import {
  Maybe,
  TariffTypeEnum,
  UserSortKeys,
  UsersRegisteredListQuery,
  UserType,
  useUsersRegisteredListQuery,
} from '@/apolloGenerated';
import {
  formatSort,
  renderCellCheckbox,
  renderCellItemValue,
  useOrganizerId,
} from '@/shared';
import { validateTariff } from '@entities/Tariff';
import {
  LoaderOverlay,
  SortType,
  StarIcon,
  TableController,
  Typography,
} from '@letsdance/ui-kit';

const pageSize = 10;
const DEFAULT_PAGE = 1;

export interface RegisteredContactSelectTableProps {
  searchValue: string;
  selected: UserType[];
  type?: TariffTypeEnum;
  productUuid?: Maybe<string>;
  onChange(uuids: UserType[]): void;
  requiredRegistration?: boolean;
  checkHasInvite?: boolean;
}

const headers = [
  { hide: true, key: true, value: 'uuid' },
  {
    value: 'check',
    width: 48,
  },
  { value: 'special', width: 48 },
  { title: 'ФИО', value: 'name' },
  { title: 'Данные', value: 'data' },
];

export const RegisteredContactSelectTable: FC<
  RegisteredContactSelectTableProps
> = ({
  checkHasInvite,
  onChange,
  productUuid,
  requiredRegistration,
  searchValue,
  selected,
  type,
}) => {
  const organizerId = useOrganizerId()!;
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<UserSortKeys>(UserSortKeys.CreatedAt);
  const validationParams = {
    skipCheckInvite: !checkHasInvite,
    skipRegistered: !requiredRegistration,
  };
  const filterUsers = (
    users: UsersRegisteredListQuery['users']['rows'],
  ): UserType[] =>
    users.filter(
      (el) =>
        validateTariff(
          {
            hasInviteByProduct: el.hasInviteByProduct,
            hasOrderByProduct: el.hasOrderByProduct,
            tariffType: type,
            userInfo: el,
          },
          validationParams,
        ).status,
    ) as UserType[];

  useEffect(() => {
    onChange(filterUsers(selected));
  }, [productUuid, type]);

  const { data, loading, previousData } = useUsersRegisteredListQuery({
    skip: !organizerId || !productUuid,
    variables: {
      filters: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
        search: searchValue,
      },
      organizerId,
      productUuid: productUuid!,
    },
  });
  const curData = data || previousData;

  const handleChange = (checked: boolean, user: UserType) => {
    if (checked) {
      onChange([...selected, user]);
    } else {
      onChange(selected.filter((el) => el.uuid !== user.uuid));
    }
  };

  const rowTemplate = (user: UsersRegisteredListQuery['users']['rows'][0]) => {
    const validation = validateTariff(
      {
        hasInviteByProduct: user.hasInviteByProduct,
        hasOrderByProduct: user.hasOrderByProduct,
        tariffType: type,
        userInfo: user,
      },
      validationParams,
    );
    const checked = selected.some((el) => user.uuid === el.uuid);

    return {
      check: renderCellCheckbox({
        disabled: !validation.status,
        onChange(val: boolean) {
          handleChange(val, user as UserType);
        },
        tooltipText: validation.message,
        value: checked,
      }),
      data: renderCellItemValue({
        label: (
          <div className="flex">
            {user.username ? (
              <Typography
                variant="body-14"
                color="on-surface-primary-1"
                rel="noreferrer">
                @{user.username}
              </Typography>
            ) : (
              '-'
            )}{' '}
            {user.phone && ' / ' + user.phone}
          </div>
        ),
        value: user.email,
      }),
      name: renderCellItemValue({
        label: `${user.last_name || ''} ${user.first_name}`,
        value: user.uuid,
      }),
      special: (
        <StarIcon
          color={
            user.isSpecial ? 'var(--on-surface-secondary-1)' : 'transparent'
          }
        />
      ),
      uuid: user.uuid,
    };
  };
  const items = useMemo(
    () => (curData?.users.rows || []).map((item) => rowTemplate(item)),
    [curData, selected],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={curData?.users.count}
        pageSize={pageSize}
        onChangePage={setPage}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as UserSortKeys);
        }}
        notResetPage
      />
    </div>
  );
};
