import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  Maybe,
  UsersListQuery,
  UserSortKeys,
  useUsersListQuery,
  useUsersSpecialListQuery,
} from '@/apolloGenerated';
import {
  formatAmount,
  formatSort,
  renderCellDate,
  renderCellItemValue,
  useOrganizerId,
} from '@/shared';
import { RouterName } from '@app/router';
import {
  LoaderOverlay,
  SortType,
  StarIcon,
  TableController,
  Typography,
} from '@letsdance/ui-kit';
import { ContactTabs } from '@widgets/Contact';

import { ContactControl } from '../ContactControl/ContactControl';

const headers = [
  { hide: true, key: true, value: 'uuid' },
  { value: 'special', width: 48 },
  { sortable: true, title: 'Дата регистрации', value: 'createdAt', width: 180 },
  { title: 'Фамилия/имя/ID', value: 'name' },
  { title: 'Данные', value: 'data' },
  { title: 'Покупки', value: 'orders' },
  { title: 'Посещено', value: 'visited' },
  { title: 'Потрачено, ₽', value: 'receipts' },
  { title: '', value: 'settings', width: 48 },
];
const pageSize = 15;
const DEFAULT_PAGE = 1;

export interface ContactTableProps {
  type?: ContactTabs;
  searchValue: string;
}

export const ContactTable: FC<ContactTableProps> = ({ searchValue, type }) => {
  const { urlFor } = useNavigate();
  const organizerId = useOrganizerId()!;
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<UserSortKeys>(UserSortKeys.CreatedAt);

  useEffect(() => {
    setPage(DEFAULT_PAGE);
  }, [type]);

  const filters = {
    order: { sort: formatSort(sort), sortBy },
    pagination: { page, pageSize },
    search: searchValue,
  };
  const {
    data: curData,
    loading: loadingData,
    previousData,
  } = useUsersListQuery({
    skip: !organizerId || type === ContactTabs.Special,
    variables: {
      filters,
      organizerId,
      ...(type === ContactTabs.Active ? { isActive: true } : {}),
    },
  });
  const {
    data: curDataSpecial,
    loading: loadingSpecialData,
    previousData: prevDataSpecial,
  } = useUsersSpecialListQuery({
    skip: !organizerId || type !== ContactTabs.Special,
    variables: {
      filters,
      organizerId,
    },
  });
  const loading = loadingData || loadingSpecialData;
  const data = useMemo(() => {
    if (type === ContactTabs.Special) {
      return curDataSpecial?.specialUsers || prevDataSpecial?.specialUsers;
    }

    return curData?.users || previousData?.users;
  }, [type, curData, curDataSpecial, previousData, prevDataSpecial]);

  const rowTemplate = (user: UsersListQuery['users']['rows'][0]) => ({
    createdAt: renderCellDate({ time: true, timestamp: user.createdAt }),
    data: renderCellItemValue({
      label: (
        <div className="flex">
          {user.username ? (
            <Typography
              variant="body-14"
              color="on-surface-primary-1"
              rel="noreferrer"
              style={{ marginRight: 4 }}>
              @{user.username}
            </Typography>
          ) : (
            '-'
          )}{' '}
          {user.phone && ' / ' + user.phone}
        </div>
      ),
      value: user.email,
    }),
    name: renderCellItemValue({
      label: `${user.last_name || ''} ${user.first_name}`,
      value: user.uuid,
    }),
    orders: String(user.ordersCount),
    receipts: formatAmount(user.receipts, true),
    settings: (
      <ContactControl
        uuid={user.uuid}
        username={user.username}
        name={`${user.last_name || ''} ${user.first_name}`}
        isSpecial={user.isSpecial}
      />
    ),
    special: (
      <StarIcon
        color={user.isSpecial ? 'var(--on-surface-secondary-1)' : 'transparent'}
      />
    ),
    uuid: user.uuid,
    visited: String(user.activatedOrdersCount),
  });
  const items = useMemo(
    () => (data?.rows || []).map((item) => rowTemplate(item)),
    [data],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={data?.count}
        pageSize={pageSize}
        onChangePage={setPage}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as UserSortKeys);
        }}
        onClickRow={(e, user) =>
          window.open(
            urlFor(RouterName.AdminContactEdit, { uuid: user.uuid }),
            '_blank',
          )
        }
        notResetPage
      />
    </div>
  );
};
