import React, { FC, useEffect, useState } from 'react';

import { UserType, useTariffQuery } from '@/apolloGenerated';
import {
  ContactListFilter,
  ContactListFilterData,
  RegisteredContactSelectTable,
  SpecialContactSelectTable,
} from '@features/Contact';
import { Maybe, Spacer, StarIcon, TabItem, Tabs } from '@letsdance/ui-kit';

enum ContactSelectTabs {
  Special = 'special',
  Registered = 'registered',
}
export interface ContactSelectProps {
  tariffUuid?: Maybe<string>;
  productUuid?: Maybe<string>;
  onChange(uuids: string[]): void;
  requiredRegistration?: boolean;
  checkInvite?: boolean;
}
export const ContactSelect: FC<ContactSelectProps> = ({
  checkInvite,
  onChange,
  productUuid,
  requiredRegistration,
  tariffUuid,
}) => {
  const [tab, setTab] = useState<ContactSelectTabs>(ContactSelectTabs.Special);
  const { data } = useTariffQuery({
    skip: !tariffUuid,
    variables: { uuid: tariffUuid! },
  });
  const [value, setValue] = useState<UserType[]>([]);
  const [filter, setFilter] = useState<ContactListFilterData>({ search: '' });

  const handleChange = (val: UserType[]) => {
    setValue(val);
    onChange(val.map((el) => el.uuid));
  };

  const handleReset = () => {
    setValue([]);
    onChange([]);
  };

  useEffect(() => {
    handleReset();
  }, [tab]);

  return (
    <div>
      <Tabs initValue={tab} onChange={setTab}>
        <TabItem
          style={{ alignItems: 'center', display: 'flex', gap: 4 }}
          value={ContactSelectTabs.Special}>
          <StarIcon width={16} height={16} />
          Спецгости
        </TabItem>
        <TabItem
          value={ContactSelectTabs.Registered}
          label="Зарегистрированные"
        />
      </Tabs>
      <Spacer size={8} />
      <ContactListFilter initValue={filter} onChange={setFilter} />
      {tab === ContactSelectTabs.Registered && (
        <RegisteredContactSelectTable
          selected={value}
          onChange={handleChange}
          searchValue={filter.search}
          type={data?.tariff.type}
          productUuid={productUuid}
          requiredRegistration={requiredRegistration}
          checkHasInvite={checkInvite}
        />
      )}
      {tab === ContactSelectTabs.Special && (
        <SpecialContactSelectTable
          selected={value}
          onChange={handleChange}
          searchValue={filter.search}
          type={data?.tariff.type}
          productUuid={productUuid}
          requiredRegistration={requiredRegistration}
          checkHasInvite={checkInvite}
        />
      )}
    </div>
  );
};
